import React from "react";
import * as style from "./toggle.module.css";
import { Link } from "gatsby";
import { motion } from "framer-motion";

const Toggle = ({ colour, to, rotate }) => {
    return (
        <Link
            to={`/${to}`}
            className={style.toggle}
            style={{ transform: `rotate(${rotate ? "45deg" : "0deg"})` }}
        >
            <motion.svg
                width="50"
                height="50"
                viewBox="0 0 40 40"
                whileHover={{
                    scale: 1.1,
                }}
                whileTap={{
                    scale: 0.9,
                }}
            >
                <line
                    y1="20"
                    x2="40"
                    y2="20"
                    fill="none"
                    stroke={colour ? colour : "#000000"}
                    strokeWidth="3"
                    vectorEffect="non-scaling-stroke"
                />
                <line
                    x1="20"
                    x2="20"
                    y2="40"
                    fill="none"
                    stroke={colour ? colour : "#000000"}
                    strokeWidth="3"
                    vectorEffect="non-scaling-stroke"
                />
            </motion.svg>
        </Link>
    );
};

export default Toggle;
